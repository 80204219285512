<template>
	<desModal :titleRight="showCity" title="城市列表" @clickDelAction="clickDel" class="city-b1" widthNum="35%" >
		<div class="city-b">
			<div class="pd10">
				<el-row type="flex" justify="space-between">
					<el-input
						placeholder="拼音全称/英文全称/中文名/三字码"
						prefix-icon="el-icon-search"
						class="mr10"
						v-model="searchVal"
						@input="searchInput"
						@focus="inputFocus"
						@blur="inputBlur"
					></el-input>
					<el-button type="danger">搜索</el-button>
				</el-row>
				<div class="h400">
					<el-row class="pd10" v-for="(item, a) in searchCityResult" :key="a">
						<block @click="bindSelectAllCity(item)">
							<div class="ft16" style="cursor:pointer;">
								<span>({{ item.CityCode }})</span>
								<span>{{ item.TypeName }}，{{ item.CityNameCN }}，{{ item.CountryName }}</span>
								<!-- <span>(Q</span>
							<strong style="color: red;">S</strong>
							<span>F) 所有机场,</span>
							<strong style="color: red;">S</strong>
							<span>ÉTIF,阿尔及利亚</span> -->
							</div>
							<div class="ft12 gray9" style="cursor:pointer;">
								<span>{{ item.CityNameEN }},{{ item.CityCode }},{{ item.CountryCode }}</span>
								<!-- <span>(Q</span>
							<strong style="color: red;">S</strong>
							<span>F) 所有机场,</span>
							<strong style="color: red;">S</strong>
							<span>ÉTIF,阿尔及利亚</span> -->
							</div>
						</block>
					</el-row>
					<!-- <div class="pd10 ft16">历史城市</div>
					<div>
						<span class="city">广州</span>
						<span class="city">广州</span>
						<span class="city">广州</span>
						<span class="city">广州</span>
					</div> -->
					<block v-if="!isInSerach">
						<div class="pd10 ft14">热门城市</div>
						<div>
							<el-row type="flex" justify="space-between" class="date-box" v-if="hotcitytype!='trainticket'">
								<div :class="'w50' + (dateActive == 1 ? ' calendar-active' : '')" @click="changeDate(1)">国内城市</div>
								<div :class="'w50' + (dateActive == 2 ? ' calendar-active' : '')" @click="changeDate(2)">国际城市</div>
							</el-row>
						</div>
						<div v-if="dateActive == 1">
							<span style="cursor:pointer;" v-for="(item, a) in domesticHotCityList" :key="a" @click="bindSelectCity(item)" class="city">{{ item.CityName }}</span>
							<!-- <span class="city">广州</span>
						<span class="city">广州</span>
						<span class="city">广州</span> -->
						</div>
						<div v-if="dateActive == 2">
							<span style="cursor:pointer;" v-for="(item, a) in internationalHotCityList" :key="a" @click="bindSelectCity(item)" class="city">{{ item.CityName }}</span>
							<!-- <span class="city">广州</span>
						<span class="city">广州</span>
						<span class="city">广州</span> -->
						</div>
					</block>
					<block v-if="isInSerach">
						<div>
							<span class="ft14">搜索</span>
							<span class="ft12 gray9">(我们支持全球民航城市的便捷搜索)</span>
						</div>
						<el-row type="flex">
							<el-col :span="6" style="width:27%;text-align: right;margin-right: 20px;margin-left:-9px;">例如搜索温哥华可输或</el-col>
							<el-col :span="8">拼音全称：WENGEHUA 英文全称：VANCOUVER 中文全称：温哥华</el-col>
						</el-row>
					</block>
				</div>
			</div>
		</div>
	</desModal>
</template>

<script>
import desModal from '../desModal.vue';

var util = require('@/utils/util.js');
var destApi = require('@/api/destApi.js');
var that;

export default {
	name: 'city',
	components: {
		desModal
	},
	data() {
		return {
			currentTab: 0,
			dateActive: '1',
			cityType: 0,
			domesticHotCityList: '', //国内热门城市
			internationalHotCityList: '', //国际热门城市
			searchCityResult: '', //搜索结果
			searchVal: '',
			isInSerach: false,			
		};
	},
	props: ['showCity','hotcitytype','top','left'],
	created() {
		that = this;
		var query = that.$route.query;
		console.log(query);
		console.log(that.hotcitytype);
		getHotCityList();
	},
	methods: {
		clickDel() {
			this.$emit('clickCityDel');
		},
		changeDate(index) {
			console.log(index);
			this.dateActive = index;
		},
		//搜索事件
		searchInput: function(e) {
			var searchKey = e;
			that.searchVal = searchKey;
			console.log(e);
			// var searchKey = e.detail.value;
			destApi.GetSearchCityListByKey(				
				{
					searchKey: searchKey,
					hotcitytype: that.hotcitytype
				},
				function(result) {
					var searchResult = '';

					if (result.code == 0) {
						searchResult = JSON.parse(result.data.searchResult); // var hotelCityResult = JSON.parse(result.data.hotelCityResult);

						console.log(searchResult);
					}
					that.searchCityResult = searchResult;
				}
			);
		},
		//输入框获得焦点
		inputFocus: function() {
			that.isInSerach = true;
		},
		//输入框失去焦点
		inputBlur: function(e) {
			console.log(e);
			if (that.searchVal == '') {
				that.isInSerach = false;
			}
		},
		//选择城市
		bindSelectCity: function(e) {
			console.log(e);
			let citymodel = e;

			this.$emit('bindSelectCity', citymodel);
		},
		bindSelectAllCity: function(e) {
			console.log(e);

			var citydata = e;
			console.log(citydata);

			if (citydata != undefined && citydata != null) {
				var citymodel = {
					CityCode: citydata.CityCode,
					CityName: citydata.CityNameCN,
					CountryCode: citydata.CountryCode,
					IsChina: citydata.IsChina
				};

				this.$emit('bindSelectCity', citymodel);
			}
		}
	}
};

//获取热门城市列表
function getHotCityList() {
	destApi.GetHotCityList(		
		{
			hotcitytype: that.hotcitytype
		},
		function(result) {
			//console.log(hotcitylist);
			var hotcitylist = JSON.parse(result.data.hotcitylist);

			var domesticHotCityList = hotcitylist.filter(function(item) {
				return item.IsChina == true;
			});
			var internationalHotCityList = hotcitylist.filter(function(item) {
				return item.IsChina == false;
			});
			that.domesticHotCityList = domesticHotCityList;
			that.internationalHotCityList = internationalHotCityList;
		}
	);
}
</script>

<style lang="scss">
@import './city.scss';
</style>
